import React from 'react';

const Home = () => {
  return (
    <div className="home-content">
      <div className='container'>
        <div className="main">
          <p className="welcome-msg">Welcome to...</p>
          <img src="./images/bg_logo.svg" className="bg-logo" />
          <p className="download-msg">Download the App here!</p>
          <div className="app-links">
            <a href="https://apps.apple.com/us/app/drink-app/id1587599387">
              <img className="apple-icon" src="./images/appstore_icon.svg" />
            </a>
            <a href="https://apps.apple.com/us/app/drink-app/id1587599387">
              <img className="google-icon" src="./images/google_store_icon.svg" />
            </a>
          </div>
          <p className="following-msg">Follow Us</p>
          <div className="business-links">
            <a href="https://www.instagram.com/drinkapp2020/" target='_blank'>
              <img src="./images/insta.svg" className="insta-icon" />
            </a>
            <a href="https://twitter.com/drinkapp2020" target='_blank'>
              <img src="./images/twitt.svg" className="twitter-icon" />
            </a>
            <a href="https://www.facebook.com/drinkapp2020" target='_blank'>
              <img src="./images/fb.svg" className="fb-icon" />
            </a>
          </div>
        </div>
        <div className="footer">
          <hr aria-orientation="horizontal" className="divider" />
          <div className="footer-container">
            <div className="copyright">
              <p className="copyright-msg">© All Copyright 2023 by DRINK, LLC</p>
            </div>
            <div className="terms-policy">
              <a href="/terms">
                <p className="terms-msg">Terms and Condition</p>
              </a>
              <a href="/privacy-policy">
                <p className="privacy-msg">Privacy Policy</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
