import React from 'react';

const Header = (props) => {
  return (
    <div className="header">
      <a href='/'><img src="./images/bg_logo.svg" /></a>
      <p className="terms-header-title">{props.title}</p>
    </div>
  );
};

export default Header;
