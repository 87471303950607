import React from 'react';
import Header from '../components/Header';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-content">
      <Header title="Privacy Policy" />
      <div className="main">
        <div className='container'>
          <p className="privacy-title">Effective Date: April 1, 2021</p>
          <p className="privacy-desc">This privacy policy (this “Policy”) explains how personal information is
            collected, used, and disclosed by The Drink App LLC (“TDA” or “we”). This Policy applies to consumer users
            (individually referred to as “you”) of TDA websites, the TDA mobile application (the “App”), and the services
            accessible through the Website or App (collectively, our “Sites”).</p>
          <p className="privacy-desc">We reserve the right to change this Policy at any time. We will notify you
            that changes have been made by indicating on this Policy the date it was last updated. We encourage you to
            review this Policy from time-to-time to make sure you understand how any personal information you provide to
            us or collected about you will be used.</p>
          <p className="privacy-desc">Other third parties, including without limitation, nightclubs and lounges that
            market and/or provide services on the Sites, and third party payment services integrated into the Sites, may
            also collect, use, and share information about you. This Policy does not cover such third parties or their
            services. For more information about third-party privacy practices, please contact them directly.</p>
          <h2 className="privacy-title">INFORMATION WE COLLECT</h2>
          <p className="privacy-desc">We collect information about you in various ways when you use our Sites. We
            use this information for a variety of reasons, including but not limited to providing the functionality and
            improving the quality of our Sites and personalizing your overall experience.</p>
          <h2 className="privacy-title">Active Collection.</h2>
          <p className="privacy-desc">We collect information from you when you use the Sites. For example, when you
            create an account on our Sites, we may ask you to provide your name, email address, postal address, phone
            number(s), and other information. You may choose not to provide certain personal information. However, if you
            decide to do so, you may not be able to take advantage of the Service.</p>
          <p className="privacy-desc">We collect financial information, such as your payment method (i.e. valid
            credit card number, type, expiration date or other financial information) only through a third party payment
            process integrated into our App. That information is collected and stored by our third party payment
            processing company (the “Payment Processor”), and the subsequent use and storage of information is governed by
            the Payment Processor’s applicable terms of service and privacy policy.</p>
          <h2 className="privacy-title">Passive Collection.</h2>
          <p className="privacy-desc">When you use the Service, some information is also automatically collected,
            such as your Internet Protocol (IP) address, your operating system, the browser type, the address of a
            referring website, and your activity on the Service. We also may automatically collect certain logistical
            information in server logs, including information about how you use various features of the Service and
            information about the number, frequency and length of each session.</p>
          <p className="privacy-desc">We also may automatically collect certain information through the use of
            “cookies.” Cookies are small data files stored on your hard drive at the request of a website. Among other
            things, cookies help us to improve the Service and your experience. If you wish to block, erase, or be warned
            of cookies, please refer to your browser manufacturer to learn about these functions. However, if you choose
            to remove or reject cookies, this could affect certain features or services made available on the Sites.</p>
          <p className="privacy-desc">Currently, we do not employ technology that recognizes “do-not-track” signals.
            We may engage third parties, such as analytics or marketing partners, who may collect information about your
            online activities over time and across different websites when you use our Sites.</p>
          <p className="privacy-desc">We may collect device-specific information (i.e. operating system version,
            your hardware model, unique device identifiers, and mobile network information including phone number). We may
            associate your device identifiers or phone number with your TDA account.</p>
          <p className="privacy-desc">We may use Web beacons or similar technology on the Sites. Web beacons are
            small, invisible graphic images that may be used on the Sites to collect certain information and monitor user
            activity, such as to count visits and understand usage effectiveness.</p>
          <p className="privacy-desc">Personal information will be stored on secured servers, and we may store and
            process personal information in the United States and other countries.</p>
          <h2 className="privacy-title">HOW WE USE INFORMATION WE COLLECT</h2>
          <p className="privacy-desc">We use personal information collected through our Sites for the purposes
            described in this Policy or disclosed to you on our Sites or otherwise in connection with our services. For
            example, we may use your information to:</p>
          <ul className="privacy-list">
            <li className="item">to request feedback and to otherwise contact you about your use of the Service;</li>
            <li className="item">process and complete transactions in connection with the Service (e.g. to make premium
              services of nightclubs and lounges accessible to you);</li>
            <li className="item">to monitor and analyze Site usage and trends;</li>
            <li className="item">to respond to your emails, questions, comments, requests and complaints, and to provide
              customer service;</li>
            <li className="item">to provide you with news and information about our or third-party events, offers, promotions
              and services we think interest you;</li>
            <li className="item">to personalize and improve the Sites, and our users’ experiences with the Sites (such as
              providing content or features that match interests), and to increase the Sites’ functionality and user
              friendliness;</li>
            <li className="item">to send you confirmations, updates, security alerts and support and administrative messages,
              and otherwise facilitate your use of, and our administration and operation of, the Sites;</li>
            <li className="item">to notify you about important changes to the service; and</li>
            <li className="item">for any other purpose for which the information was collected.</li>
          </ul>
          <h2 className="privacy-title">HOW WE SHARE INFORMATION</h2>
          <p className="privacy-desc">When you purchase access to premium services of nightclubs and lounges (the
            “Venues”) through our App, we provide your personal information to such Venues to facilitate your access to
            the premium services. TDA does not control the privacy practices of Venues. Please contact the Venue directly
            if you want to learn about its privacy practices</p>
          <p className="privacy-desc">We do not share your personal information with third parties other than as
            described above and as follows:</p>
          <p className="privacy-desc">We may share aggregate statistical data for the improvement of services
            offered by our Sites. We may share personal information with affiliates, third-party vendors, consultants, and
            other service providers who work for us. Such third-party vendors may include vendors who provide fraud
            detection services to us and other third parties.</p>
          <p className="privacy-desc">We share personal information with third parties who provide services to us,
            such as data collection, reporting, ad response measurement, and site analytics, as well as assistance with
            delivery of relevant marketing and advertising. These third parties may view, edit, or set their own cookies.
            We and our third-party service providers, advertisers, and/or partners may also place web beacons for these
            third parties. The use of these technologies by these third parties is subject to their own privacy policies
            and is not covered by this Policy. We may also share with third parties aggregated or de-identified
            information that does not identify you.</p>
          <p className="privacy-desc">We may disclose your personal information for the following reasons: (i) to
            comply with laws and to respond to lawful requests and legal process, (ii) to protect the rights and property
            of TDA, our agents and customers, and others, including to enforce our agreements, policies, and terms of use,
            and (iii) in an emergency to protect the personal safety of you, TDA customers, or any other person.</p>
          <h2 className="privacy-title">LINKS TO OTHER WEBSITES</h2>
          <p className="privacy-desc"> fontFamily="Poppins" The Sites may contain links to other websites. Any
            personal information you provide on the linked pages is provided directly to that third party and is subject
            to that third party’s privacy policy. This Policy does not apply to such linked websites, and we are not
            responsible for the content or privacy and security practices and policies of these websites or any other
            websites that are linked to/from the Sites. We encourage you to learn about their privacy and security
            practices and policies before providing them with personally identifiable information.</p>
          <h2 className="privacy-title">SECURITY OF YOUR PERSONAL INFORMATION</h2>
          <p className="privacy-desc">We always take reasonable measures to protect your personal information to
            prevent misuse and loss, as well as any unauthorized access, disclosure, alteration, and destruction. However,
            please be aware that despite our best efforts, no security measures are perfect or impenetrable. Therefore, no
            method of data transmission can guarantee against any interception or any other type of misuse. To assist with
            the protection of personal information, you must keep your password confidential and not disclose it to any
            other person. You are responsible for all uses of the Sites and our services by any person using your
            password. Please advise us immediately if you believe your password has been misused in any manner.</p>
          <h2 className="privacy-title">YOUR INFORMATION CHOICES AND CHANGES</h2>
          <p className="privacy-desc">In the event we decide to use your personal information for any purpose (other
            than as referenced in this Policy), we will offer you an effective way to opt out of the use of your personal
            information for those other purposes. You may opt out of receiving promotional emails from TDA by following
            the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as emails
            about your TDA account or our ongoing business relations.</p>
          <h2 className="privacy-title">CONTACTING TDA</h2>
          <p className="privacy-desc">For questions about this Policy or accessing, changing, or deleting your
            personal information you may contact TDA at <a href='tel:781.742.5506' className='contact-info'>781.742.5506</a> or at <a href='mailto:DrinkApp2020@gmail.com' className='contact-info'>DrinkApp2020@gmail.com</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
