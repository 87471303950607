import { ButtonDefaultStyles } from './ButtonDefaultStyles.js';

class ScrollToTop {
  constructor(options = {}) {
    this.options = {
      bgColor: '#000',
      color: '#fff',
      style: undefined,
      visibilityOffset: 300,
      icon: '&#9650;',
      width: '50px',
      height: '40px',
      position: 'right',
      ...options,
    };
    this.scrollButton = undefined;
    this.scrollTopEvtListener = undefined;
    this.windowEvtListener = undefined;
    this.ticking = undefined;
  }

  init() {
    if (document && window) {
      this.scrollButton = document.createElement('div');
      this.scrollButton.setAttribute('data-js-scroll-top', '');
      this.scrollButton.classList.add('scroll-top-element');
      this.scrollButton.style.cssText =
        this.options.style || ButtonDefaultStyles(this.options) || '';
      
      const arrowIcon = document.createElement('div');
      if (typeof this.options.icon === 'string') {
        arrowIcon.innerHTML = this.options.icon;
      } else if (this.options.icon instanceof HTMLElement) {
        arrowIcon.appendChild(this.options.icon);
      }
      
      this.scrollButton.appendChild(arrowIcon);
      document.body.appendChild(this.scrollButton);
      
      this.windowEvtListener = () => {
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            if (window.scrollY > this.options.visibilityOffset) {
              this.scrollButton.style.transform = 'translateY(0)';
            } else {
              this.scrollButton.style.transform = 'translateY(105%)';
            }
            this.ticking = false;
          });
          this.ticking = true;
        }
      };
      document.addEventListener('scroll', this.windowEvtListener);
      
      this.scrollTopEvtListener = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      };
      this.scrollButton.addEventListener('click', this.scrollTopEvtListener);
    }
    return null;
  }

  destroy() {
    if (this.scrollButton) {
      document.removeEventListener('scroll', this.windowEvtListener);
      this.scrollButton.removeEventListener('click', this.scrollTopEvtListener);
      document.body.removeChild(this.scrollButton);
      this.scrollButton = undefined;
      this.windowEvtListener = undefined;
      this.scrollTopEvtListener = undefined;
      this.ticking = undefined;
      this.options = undefined;
    }
    return null;
  }
}

export default ScrollToTop;