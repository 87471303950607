import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";

const SmoothScrollToTop = () => {
  useEffect(() => {
    const icon = document.createElement("div");
    icon.style.cssText = `
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
    `;

    const scrollTop = new ScrollToTop({ icon });
    scrollTop.init();

    return () => {
      scrollTop.destroy();
    };
  }, []);

  return null; // This component doesn't render anything visible
};

export default SmoothScrollToTop;
